import Vue from "vue";
import Vuex from "vuex";
import login from "./login";
import offices from "./offices";
import users from "./users";
import tasks from "./tasks";
import clients from "./clients";
import statistics from "./statistics"

Vue.use(Vuex);

export default new Vuex.Store({
  namedspaced: true,
  modules: {
    login: login,
    offices: offices,
    tasks: tasks,
    users: users,
    clients:clients,
    statistics: statistics
  },
});
