import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  browserSessionPersistence,
} from "firebase/auth";
import router from "../../router";
import ENUM from "@/store/enums";
import {
  // connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

const state = {
  user: null,
  companyID: "",
  status: ENUM.INIT,
  companyZones: [],
  currentUser: null,
  textError: "",
  // companyId: "",
  userType: "",
  companyDetails: [],
  updateCompany: false,
  initSession: false,
};

const getters = {
  isAuthenticated: (state) => state.user,
  showTextError: (state) => state.textError,
  globalStatus: (state) => state.status,
  userType: (state) => state.user.user_type,
  getZones: (state) => state.companyZones,
  getUserUID: (state) => state.user.uid,
  getCompanyID: (state) => state.user.companyID,
  AllState: (state) => state,
  UserType: (state) => state.userType,
  companyDetails: (state) => state.companyDetails,
  sessionStatus: (state) => state.initSession,
  companyName: (state) => state.companyDetails.companyName,
};

const mutations = {
  SET_USER(state, user) {
    state.user = user;
    state.status = ENUM.LOADED;
  },
  SET_USERTYPE(state, userType) {
    state.userType = userType;
  },
  AUTH_STATUS_LOADING(state, newInit) {
    state.initSession = newInit;
  },
  AUTH_STATUS_LOADED(state) {
    state.status = ENUM.LOADED;
  },

  AUTH_TEXT_ERROR(state, text) {
    state.textError = text;
  },
  CLEAR_USER(state) {
    state.user = null;
    state.status = ENUM.INIT;
  },
  SET_ZONES(state, companyZones) {
    state.companyZones = companyZones;
    state.status = ENUM.LOADED;
  },
  SET_NEW_USER(state, user) {
    state.currentUser = user;
    state.status = ENUM.LOADED;
  },
  SET_COMPANY_ID(state, companyID) {
    state.companyID = companyID;
  },
  COMPANY_DETAILS(state, companyDetails) {
    state.companyDetails = companyDetails;
  },
  UPDATE_COMPANY(state, updateCompany) {
    state.updateCompany = updateCompany;
  },
};

const actions = {
  async loginWithEmailAndPassword({ commit }, payload) {
    let regExpEmail =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/; // eslint-disable-line

    let tempEmail = payload.userLoginEmail.trim();
    let tempPass = payload.userPassword.trim();

    let isInvalidEmail = !regExpEmail.test(tempEmail);
    commit("AUTH_STATUS_LOADING", false);

    if (!tempEmail || !tempPass) {
      commit("AUTH_TEXT_ERROR", "Complete los campos para continuar");
      return;
    } else if (isInvalidEmail) {
      commit("AUTH_TEXT_ERROR", "Datos incorrectos");
      return;
    }

    const auth = getAuth();
    setPersistence(auth, browserSessionPersistence);

    await signInWithEmailAndPassword(auth, tempEmail, tempPass)
      .then((result) => {
        commit("AUTH_STATUS_LOADED");
        commit("SET_USER", result.user);
        const functions = getFunctions();
        // connectFunctionsEmulator(functions, "localhost", 5001);
        const readUserById = httpsCallable(functions, "readUserById");
        readUserById({ inputUserID: result.user.uid }).then((response) => {
          const companyID = response.data[0].userCompanyId;
          commit("SET_USERTYPE", response.data[0].userType);
          commit("SET_COMPANY_ID", companyID);
          if (response.data[0].userType !== "ADMIN") {
            router.push("/reportes");
          } else {
            router.push("/mi-cuenta");
          }
        });
      })
      .catch((error) => {
        // FALLO
        let errorMessage = error.message;
        if (errorMessage === "Firebase: Error (auth/wrong-password).") {
          commit("AUTH_TEXT_ERROR", "Ingrese correctamente los Campos");
          return;
        } else if (errorMessage === "Firebase: Error (auth/user-not-found).") {
          commit("AUTH_STATUS_ERROR");
          commit("AUTH_TEXT_ERROR", "No existe usuario");
        } else {
          commit(
            "AUTH_TEXT_ERROR",
            "Hubo un problema de conexión comuniquese con el administrador del sistema."
          );
        }
      });
  },
  async initializeAuth({ commit }, user) {
    if (user) {
      commit("SET_USER", user);
      commit("AUTH_STATUS_LOADED");
      if (router.currentRoute.name === "loginView") {
        const functions = getFunctions();
        // connectFunctionsEmulator(functions, "localhost", 5001);
        const readUserById = httpsCallable(functions, "readUserById");
        await readUserById({ inputUserID: user.uid }).then((response) => {
          const companyID = response.data[0].userCompanyId;
          commit("SET_USERTYPE", response.data[0].userType);
          commit("SET_COMPANY_ID", companyID);
          if (response.data[0].userType !== "ADMIN") {
            router.push("/reportes");
          } else {
            router.push("/mi-cuenta");
          }
        });
      }
    } else {
      commit("AUTH_STATUS_ERROR");
    }
  },

  async logout({ commit }) {
    const auth = getAuth();
    await signOut(auth);
    commit("CLEAR_USER");
    router.replace("/");
  },
  async readCompaniesById({ commit }) {
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const getCompanyInfo = httpsCallable(functions, "readCompaniesById");
    const readCompanyId = this.state.login.companyID;
    await getCompanyInfo({
      companyID: readCompanyId,
    }).then((result) => {
      const companyDetails = result.data;
      commit("COMPANY_DETAILS", companyDetails);
    });
  },
  async updateCompanyById({ commit }, payload) {
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const updateCompanyById = httpsCallable(functions, "updateCompanyById");
    await updateCompanyById(payload).then(() => {
      commit("UPDATE_COMPANY", true);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
