import Vue from "vue";
import Vuex from "vuex";
import ENUM from "@/store/enums";
import Toastify from 'toastify-js'
import {
  // connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

Vue.use(Vuex);

const state = {
  user: "",
  users: [],
  users1: [],
  userType: "ADMIN",
  allUsersByCompany: [],
  loader: false,
  userCompanyId: "",
  companyID: "",
  userCreatedOk: false,
  emailInUse: false,
  status: ENUM.INIT,
  userSelected: [],
  userDeleted: false,
  userChangePassword: "",
  firstOfficeCreate: "",
  searchingUsers: false,
  userEdit: false,
  passEdit: false,
};
const getters = {
  allUsersByOffice: (state) => state.users,
  allUsersByOffice1: (state) => state.users1,
  allUsersByCompany: (state) => state.allUsersByCompany,
  getUserSelected: (state) => state.userSelected[0],
  loaderUser: (state) => state.loader,
  loadingUserFull: (state) => state.loadingUserFull,
  userEdit: (state) => state.userEdit,
};
const actions = {
  async createUser({ commit }, payload) {
    commit("LOADING", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const createNewUser = httpsCallable(functions, "createNewUser");
    await createNewUser(payload).then((result) => {
      const newUser = result.data;
      const exist = payload.userLoginEmail + " " + "is already in use!";
      if (newUser === exist) {
        commit("LOADING", false);
        commit("EMAIL_IN_USE", true);
      } else {
        const limit = "Users limit reached, User with name" + " " + payload.userName + " " + "hasn't been created"
        if (newUser === limit) {
          commit("LOADING", false);
          commit("USER_CREATED_ALERT", false);
          Toastify({
            text: "Has alcanzado el limite de Usuarios, comunícate con el equipo de ventas.",
            duration: 5000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            style: {
              background: "#1d3051",
            },
          }).showToast();
        } else {
          // "Users limit reached, User with name Nuevo hasn't been created"
          commit("SET_NEW_USER", newUser);
          commit("LOADING", false);
          commit("USER_CREATED_ALERT", true);
          const officeID = payload.userWorkOffice[0];
          this.dispatch("readUserByOfficeID", officeID);
        }
      }
    });
  },
  async readUserByOfficeID({ commit }, payload) {
    commit("LOADING", true);
    const body = {
      companyID: this.state.login.companyID,
      officeID: payload,
    };
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const redUserByOfficeID = httpsCallable(functions, "readUserByOffice");
    await redUserByOfficeID(body).then((result) => {
      const redUsersByOffice = result.data;
      // console.log(redUsersByOffice, "redUsersByOffice 1")
      commit("GET_USERS_BY_OFFICE", redUsersByOffice);
      commit("LOADING", false);
    });
  },
  async updateUserById({ commit }, payload) {
    commit("LOADING", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const updateUserById = httpsCallable(functions, "updateUserById");
    await updateUserById(payload).then((res) => {
      commit("GET_USERS_BY_OFFICE", res.data);
      commit("LOADING", false);
      commit("USER_EDITED_ALERT", true);
      this.dispatch("readUserByOfficeID", this.state.offices.officeSelectedID);
    });
  },
  async deleteUserByID({ commit }, payload) {
    commit("LOADING", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const deleteUserByID = httpsCallable(functions, "deleteUsersById");
    await deleteUserByID(payload).then(() => {
      const redUserByOfficeID = httpsCallable(functions, "readUserByOffice");
      const body = {
        companyID: this.state.login.companyID,
        officeID: payload,
      };
      redUserByOfficeID(body).then((result) => {
        const redUsersByOffice = result.data;
        const officeID = this.state.offices.officeSelectedID;
        this.dispatch("readUserByOfficeID", officeID);
        commit("LOADING", false);
        commit("USER_DELETED_ALERT", true);
        commit("GET_USERS_BY_OFFICE", redUsersByOffice);
      });
    });
  },

  async changePasswordByID({ commit }, payload) {
    commit("LOADING", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const changePasswordByID = httpsCallable(
      functions,
      "updateUserPasswordById"
    );
    await changePasswordByID(payload).then(() => {
      commit("LOADING", false);
      commit("CHANGE_PASSWORD", payload.userUID);
      commit("PASS_EDITED_ALERT", true);
    });
  },
  async readUserByCompany({ commit }, payload) {
    commit("LOADING", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readUserByCompany = httpsCallable(
      functions,
      "readUserByCompany"
    );
    await readUserByCompany(payload).then((result) => {
      const allUsersByCompany = result.data
      commit("ALL_USERS_BY_COMPANY", allUsersByCompany)
      commit("LOADING", false);
    });
  },
};
const mutations = {
  SET_NEW_USER(state, newUser) {
    state.user = newUser;
  },
  GET_USERS_BY_OFFICE(state, getUserByID) {
    state.users = getUserByID;
    state.users1 = getUserByID;
    state.status = ENUM.LOADED;
  },
  GET_USER_BY_ID(state, userUID) {
    state.userSelected;
    let allUsers = state.users.filter((a) => a.userUID === userUID);
    state.userSelected = allUsers;
  },
  ALL_USERS_BY_COMPANY(state, allUsersByCompany) {
    state.allUsersByCompany = allUsersByCompany
  },
  CHANGE_PASSWORD(state, userUID) {
    state.userChangePassword = userUID;
  },
  USER_CREATED_ALERT(state, userCreatedY) {
    state.userCreatedOk = userCreatedY;
  },
  USER_DELETED_ALERT(state, userDeletedA) {
    state.userDeleted = userDeletedA;
  },
  USER_EDITED_ALERT(state, editUs) {
    state.userEdit = editUs;
  },
  PASS_EDITED_ALERT(state, editPassword) {
    state.passEdit = editPassword;
  },
  EMAIL_IN_USE(state, emailUse) {
    state.emailInUse = emailUse;
  },
  LOADING(state, newLoadingStatus) {
    state.loader = newLoadingStatus;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
