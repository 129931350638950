import { getFunctions, httpsCallable } from "firebase/functions";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const state = {
  graphicReports: false,
  graphicHours: false,
  minutesByYear: [
    {
      date: "2021-01-01",
      media: 0,
    },
    {
      date: "2021-02-01",
      media: 0,
    },
    {
      date: "2021-03-01",
      media: 0,
    },
    {
      date: "2021-04-01",
      media: 0,
    },
    {
      date: "2021-05-01",
      media: 0,
    },
    {
      date: "2021-06-01",
      media: 0,
    },
    {
      date: "2021-07-01",
      media: 0,
    },
    {
      date: "2021-08-01",
      media: 0,
    },
    {
      date: "2021-09-01",
      media: 0,
    },
    {
      date: "2021-10-01",
      media: 0,
    },
    {
      date: "2021-11-01",
      media: 0,
    },
    {
      date: "2021-12-01",
      media: 0,
    },
  ],
  reportsByYear:[
    {
      date: "2021-01-01",
      media: 0,
    },
    {
      date: "2021-02-01",
      media: 0,
    },
    {
      date: "2021-03-01",
      media: 0,
    },
    {
      date: "2021-04-01",
      media: 0,
    },
    {
      date: "2021-05-01",
      media: 0,
    },
    {
      date: "2021-06-01",
      media: 0,
    },
    {
      date: "2021-07-01",
      media: 0,
    },
    {
      date: "2021-08-01",
      media: 0,
    },
    {
      date: "2021-09-01",
      media: 0,
    },
    {
      date: "2021-10-01",
      media: 0,
    },
    {
      date: "2021-11-01",
      media: 0,
    },
    {
      date: "2021-12-01",
      media: 0,
    },
  ],
  minutesTotal: 0,
  reportsTotal:0,
  dataMinutes:[],
  dataReports:[],
  typeShowGraph:"",
  officeIDSelected:"",
  clientIDSelected:"",
  yearSelected:"2023",
  namePdfSelected: "",
  loader:false
};
const actions = {
  async readCompanyDataSumByYear({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readCompanyDataSumByYear = httpsCallable(functions, "readCompanyDataSumByYear");
    await readCompanyDataSumByYear(payload).then((response) => {
      if (typeof (response.data) === typeof ("") || response.data === `"Company with ID ${payload.companyID} doesn't exists"`) {
        let minutesByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-04-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: 0,
          },
        ]
        let reportsByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-04-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: 0,
          },
        ]
        let formattedData = minutesByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataMinutes = [
          {
            name: "test",
            data: formattedData,
          },
        ];
        let formattedDataReports = reportsByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataReports = [
          {
            name: "test",
            data: formattedDataReports,
          },
        ];
        // console.log(dataReports)
        commit("SET_VALUES_GRAPH_MINUTES", dataMinutes)
        commit("SET_VALUES_GRAPH_REPORTS", dataReports)
        commit("SET_MINUTES_BY_YEAR", minutesByYear)
        commit("SET_REPORTS_BY_YEAR", reportsByYear)
        commit("SET_REPORTS_TOTAL", 0)
        commit("SET_MINUTES_TOTAL", 0)
        commit("LOADING_STATUS", false);
      } else {

        let minutes = response.data.minutes
        let reports = response.data.tasks
        let reportsByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: response.data.tasks.january ? response.data.tasks.january : 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: response.data.tasks.february ? response.data.tasks.february : 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media:response.data.tasks.march ? response.data.tasks.march : 0,
          },
          {
            date:`${payload.yearInput}-04-01`,
            media: response.data.tasks.april ? response.data.tasks.april :0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: response.data.tasks.may ? response.data.tasks.may :0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: response.data.tasks.june ? response.data.tasks.june : 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: response.data.tasks.july ? response.data.tasks.july :0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: response.data.tasks.august ? response.data.tasks.august :0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: response.data.tasks.september ? response.data.tasks.september :0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: response.data.tasks.october ? response.data.tasks.october :0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: response.data.tasks.november ? response.data.tasks.november : 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: response.data.tasks.december ? response.data.tasks.december :0,
          },
        ]
        let minutesByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: response.data.minutes.january ? response.data.minutes.january: 0 ,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: response.data.minutes.february ? response.data.minutes.february : 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: response.data.minutes.march ? response.data.minutes.march : 0,
          },
          {
            date:`${payload.yearInput}-04-01`,
            media: response.data.minutes.april ? response.data.minutes.april : 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: response.data.minutes.may ? response.data.minutes.may : 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: response.data.minutes.june ? response.data.minutes.june : 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: response.data.minutes.july ? response.data.minutes.july : 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: response.data.minutes.august ? response.data.minutes.august : 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: response.data.minutes.september ? response.data.minutes.september : 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: response.data.minutes.october ? response.data.minutes.october : 0 ,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: response.data.minutes.november ? response.data.minutes.november : 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: response.data.minutes.december ? response.data.minutes.december :0,
          },
        ]

        let minutesTotal = minutes.january? minutes.january : 0 + minutes.february ? minutes.february: 0 + minutes.march ? minutes.march : 0 + minutes.april ? minutes.april : 0 + minutes.may ? minutes.may : 0 + minutes.june?minutes.june:0 + minutes.july ? minutes.july :0 + minutes.august ? minutes.august :0 + minutes.september ? minutes.september:0 + minutes.october ? minutes.october : 0 + minutes.november ? minutes.november :0 + minutes.december ? minutes.december : 0


        let reportsTotal = reports.january ? reports.january : 0 + reports.february ? reports.february :0 + reports.march ? reports.march : 0 + reports.april ? reports.april : 0 + reports.may ? reports.may : 0 + reports.june ? reports.june : 0 + reports.july ? reports.july : 0 + reports.august ? reports.august : 0 + reports.september ? reports.september : 0 + reports.october ? reports.october : 0 + reports.november ? reports.november : 0 + reports.december ? reports.december : 0
       
        let num = minutesTotal;
        let hours = num / 60;
        let rhours = Math.floor(hours);
        let minutes_cal = (hours - rhours) * 60;
        let rminutes = Math.round(minutes_cal);
        minutesTotal = `${rhours}hrs ${rminutes}min`;
        
        let formattedData = minutesByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataMinutes = [
          {
            name: "test",
            data: formattedData,
          },
        ];
        let formattedDataReports = reportsByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataReports = [
          {
            name: "test",
            data: formattedDataReports,
          },
        ];

        commit("SET_MINUTES_BY_YEAR", minutesByYear)
        commit("SET_REPORTS_BY_YEAR", reportsByYear)
        commit("SET_MINUTES_TOTAL", minutesTotal)
        commit("SET_REPORTS_TOTAL", reportsTotal)
        commit("SET_VALUES_GRAPH_MINUTES", dataMinutes)
        commit("SET_VALUES_GRAPH_REPORTS", dataReports)
        commit("LOADING_STATUS", false);
      }
    });
  },
  async readOfficeDataSumByYear({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readOfficeDataSumByYear = httpsCallable(functions, "readOfficeDataSumByYear");
    await readOfficeDataSumByYear(payload).then((response) => {
      if (typeof (response.data) === typeof ("") || response.data === `"Company with ID ${payload.companyID} doesn't exists"`) {
        let minutesByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-04-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: 0,
          },
        ]
        let reportsByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-04-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: 0,
          },
        ]
        let formattedData = minutesByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataMinutes = [
          {
            name: "test",
            data: formattedData,
          },
        ];
        let formattedDataReports = reportsByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataReports = [
          {
            name: "test",
            data: formattedDataReports,
          },
        ];
        commit("SET_VALUES_GRAPH_MINUTES", dataMinutes)
        commit("SET_VALUES_GRAPH_REPORTS", dataReports)
        commit("SET_MINUTES_BY_YEAR", minutesByYear)
        commit("SET_REPORTS_BY_YEAR", reportsByYear)
        commit("SET_MINUTES_TOTAL", 0)
        commit("SET_REPORTS_TOTAL", 0)
        commit("LOADING_STATUS", false);
      } else {

        let minutes = response.data.minutes
        let reports = response.data.tasks
        let reportsByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: response.data.tasks.january ? response.data.tasks.january : 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: response.data.tasks.february ? response.data.tasks.february : 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media:response.data.tasks.march ? response.data.tasks.march : 0,
          },
          {
            date:`${payload.yearInput}-04-01`,
            media: response.data.tasks.april ? response.data.tasks.april :0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: response.data.tasks.may ? response.data.tasks.may :0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: response.data.tasks.june ? response.data.tasks.june : 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: response.data.tasks.july ? response.data.tasks.july :0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: response.data.tasks.august ? response.data.tasks.august :0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: response.data.tasks.september ? response.data.tasks.september :0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: response.data.tasks.october ? response.data.tasks.october :0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: response.data.tasks.november ? response.data.tasks.november : 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: response.data.tasks.december ? response.data.tasks.december :0,
          },
        ]
        let minutesByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: response.data.minutes.january ? response.data.minutes.january: 0 ,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: response.data.minutes.february ? response.data.minutes.february : 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: response.data.minutes.march ? response.data.minutes.march : 0,
          },
          {
            date:`${payload.yearInput}-04-01`,
            media: response.data.minutes.april ? response.data.minutes.april : 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: response.data.minutes.may ? response.data.minutes.may : 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: response.data.minutes.june ? response.data.minutes.june : 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: response.data.minutes.july ? response.data.minutes.july : 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: response.data.minutes.august ? response.data.minutes.august : 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: response.data.minutes.september ? response.data.minutes.september : 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: response.data.minutes.october ? response.data.minutes.october : 0 ,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: response.data.minutes.november ? response.data.minutes.november : 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: response.data.minutes.december ? response.data.minutes.december :0,
          },
        ]

        let minutesTotal = minutes.january? minutes.january : 0 + minutes.february ? minutes.february: 0 + minutes.march ? minutes.march : 0 + minutes.april ? minutes.april : 0 + minutes.may ? minutes.may : 0 + minutes.june?minutes.june:0 + minutes.july ? minutes.july :0 + minutes.august ? minutes.august :0 + minutes.september ? minutes.september:0 + minutes.october ? minutes.october : 0 + minutes.november ? minutes.november :0 + minutes.december ? minutes.december : 0


        let reportsTotal = reports.january ? reports.january : 0 + reports.february ? reports.february :0 + reports.march ? reports.march : 0 + reports.april ? reports.april : 0 + reports.may ? reports.may : 0 + reports.june ? reports.june : 0 + reports.july ? reports.july : 0 + reports.august ? reports.august : 0 + reports.september ? reports.september : 0 + reports.october ? reports.october : 0 + reports.november ? reports.november : 0 + reports.december ? reports.december : 0

        let num = minutesTotal;
        let hours = num / 60;
        let rhours = Math.floor(hours);
        let minutes_cal = (hours - rhours) * 60;
        let rminutes = Math.round(minutes_cal);
        minutesTotal = `${rhours}hrs ${rminutes}min`;

        let formattedData = minutesByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataMinutes = [
          {
            name: "test",
            data: formattedData,
          },
        ];
        let formattedDataReports = reportsByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataReports = [
          {
            name: "test",
            data: formattedDataReports,
          },
        ];
        
        commit("SET_MINUTES_BY_YEAR", minutesByYear)
        commit("SET_REPORTS_BY_YEAR", reportsByYear)
        commit("SET_MINUTES_TOTAL", minutesTotal)
        commit("SET_REPORTS_TOTAL", reportsTotal)
        commit("SET_VALUES_GRAPH_MINUTES", dataMinutes)
        commit("SET_VALUES_GRAPH_REPORTS", dataReports)
        commit("LOADING_STATUS", false);
      }
    });
  },
  async readClientDataByYear({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readClientDataByYear = httpsCallable(functions, "readClientDataByYear");
    await readClientDataByYear(payload).then((response) => {
      if (typeof (response.data) === typeof ("") || response.data === `"Company with ID ${payload.companyID} doesn't exists"`) {
        let minutesByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-04-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: 0,
          },
        ]
        let reportsByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-04-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: 0,
          },
        ]
        let formattedData = minutesByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataMinutes = [
          {
            name: "test",
            data: formattedData,
          },
        ];
        let formattedDataReports = reportsByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataReports = [
          {
            name: "test",
            data: formattedDataReports,
          },
        ];
        commit("SET_VALUES_GRAPH_MINUTES", dataMinutes)
        commit("SET_VALUES_GRAPH_REPORTS", dataReports)
        commit("SET_MINUTES_BY_YEAR", minutesByYear)
        commit("SET_REPORTS_BY_YEAR", reportsByYear)
        commit("SET_REPORTS_TOTAL", 0)
        commit("SET_MINUTES_TOTAL", 0)
        commit("LOADING_STATUS", false);
      } else {

        let minutes = response.data.minutes
        let reports = response.data.tasks
        let reportsByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: response.data.tasks.january ? response.data.tasks.january : 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: response.data.tasks.february ? response.data.tasks.february : 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media:response.data.tasks.march ? response.data.tasks.march : 0,
          },
          {
            date:`${payload.yearInput}-04-01`,
            media: response.data.tasks.april ? response.data.tasks.april :0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: response.data.tasks.may ? response.data.tasks.may :0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: response.data.tasks.june ? response.data.tasks.june : 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: response.data.tasks.july ? response.data.tasks.july :0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: response.data.tasks.august ? response.data.tasks.august :0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: response.data.tasks.september ? response.data.tasks.september :0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: response.data.tasks.october ? response.data.tasks.october :0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: response.data.tasks.november ? response.data.tasks.november : 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: response.data.tasks.december ? response.data.tasks.december :0,
          },
        ]
        let minutesByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: response.data.minutes.january ? response.data.minutes.january: 0 ,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: response.data.minutes.february ? response.data.minutes.february : 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: response.data.minutes.march ? response.data.minutes.march : 0,
          },
          {
            date:`${payload.yearInput}-04-01`,
            media: response.data.minutes.april ? response.data.minutes.april : 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: response.data.minutes.may ? response.data.minutes.may : 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: response.data.minutes.june ? response.data.minutes.june : 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: response.data.minutes.july ? response.data.minutes.july : 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: response.data.minutes.august ? response.data.minutes.august : 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: response.data.minutes.september ? response.data.minutes.september : 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: response.data.minutes.october ? response.data.minutes.october : 0 ,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: response.data.minutes.november ? response.data.minutes.november : 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: response.data.minutes.december ? response.data.minutes.december :0,
          },
        ]

        let minutesTotal = minutes.january? minutes.january : 0 + minutes.february ? minutes.february: 0 + minutes.march ? minutes.march : 0 + minutes.april ? minutes.april : 0 + minutes.may ? minutes.may : 0 + minutes.june?minutes.june:0 + minutes.july ? minutes.july :0 + minutes.august ? minutes.august :0 + minutes.september ? minutes.september:0 + minutes.october ? minutes.october : 0 + minutes.november ? minutes.november :0 + minutes.december ? minutes.december : 0


        let reportsTotal = reports.january ? reports.january : 0 + reports.february ? reports.february :0 + reports.march ? reports.march : 0 + reports.april ? reports.april : 0 + reports.may ? reports.may : 0 + reports.june ? reports.june : 0 + reports.july ? reports.july : 0 + reports.august ? reports.august : 0 + reports.september ? reports.september : 0 + reports.october ? reports.october : 0 + reports.november ? reports.november : 0 + reports.december ? reports.december : 0

        let num = minutesTotal;
        let hours = num / 60;
        let rhours = Math.floor(hours);
        let minutes_cal = (hours - rhours) * 60;
        let rminutes = Math.round(minutes_cal);
        minutesTotal = `${rhours}hrs ${rminutes}min`;

        let formattedData = minutesByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataMinutes = [
          {
            name: "test",
            data: formattedData,
          },
        ];
        let formattedDataReports = reportsByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataReports = [
          {
            name: "test",
            data: formattedDataReports,
          },
        ];

        commit("SET_MINUTES_BY_YEAR", minutesByYear)
        commit("SET_REPORTS_BY_YEAR", reportsByYear)
        commit("SET_MINUTES_TOTAL", minutesTotal)
        commit("SET_REPORTS_TOTAL", reportsTotal)
        commit("SET_VALUES_GRAPH_MINUTES", dataMinutes)
        commit("SET_VALUES_GRAPH_REPORTS", dataReports)
        commit("LOADING_STATUS", false);
      }
    });
  },
  async readUserDataSumByYear({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readUserDataSumByYear = httpsCallable(functions, "readUserDataSumByYear");
    await readUserDataSumByYear(payload).then((response) => {
      if (typeof (response.data) === typeof ("") || response.data === `"Company with ID ${payload.companyID} doesn't exists"`) {
        let minutesByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-04-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: 0,
          },
        ]
        let reportsByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-04-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: 0,
          },
        ]
        let formattedData = minutesByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataMinutes = [
          {
            name: "test",
            data: formattedData,
          },
        ];
        let formattedDataReports = reportsByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataReports = [
          {
            name: "test",
            data: formattedDataReports,
          },
        ];
        commit("SET_VALUES_GRAPH_MINUTES", dataMinutes)
        commit("SET_VALUES_GRAPH_REPORTS", dataReports)
        commit("SET_MINUTES_BY_YEAR", minutesByYear)
        commit("SET_REPORTS_BY_YEAR", reportsByYear)
        commit("SET_REPORTS_TOTAL", 0)
        commit("SET_MINUTES_TOTAL", 0)
        commit("LOADING_STATUS", false);
      } else {

        let minutes = response.data.minutes
        let reports = response.data.tasks
        let reportsByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: response.data.tasks.january ? response.data.tasks.january : 0,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: response.data.tasks.february ? response.data.tasks.february : 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media:response.data.tasks.march ? response.data.tasks.march : 0,
          },
          {
            date:`${payload.yearInput}-04-01`,
            media: response.data.tasks.april ? response.data.tasks.april :0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: response.data.tasks.may ? response.data.tasks.may :0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: response.data.tasks.june ? response.data.tasks.june : 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: response.data.tasks.july ? response.data.tasks.july :0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: response.data.tasks.august ? response.data.tasks.august :0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: response.data.tasks.september ? response.data.tasks.september :0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: response.data.tasks.october ? response.data.tasks.october :0,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: response.data.tasks.november ? response.data.tasks.november : 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: response.data.tasks.december ? response.data.tasks.december :0,
          },
        ]
        let minutesByYear = [
          {
            date: `${payload.yearInput}-01-01`,
            media: response.data.minutes.january ? response.data.minutes.january: 0 ,
          },
          {
            date: `${payload.yearInput}-02-01`,
            media: response.data.minutes.february ? response.data.minutes.february : 0,
          },
          {
            date: `${payload.yearInput}-03-01`,
            media: response.data.minutes.march ? response.data.minutes.march : 0,
          },
          {
            date:`${payload.yearInput}-04-01`,
            media: response.data.minutes.april ? response.data.minutes.april : 0,
          },
          {
            date: `${payload.yearInput}-05-01`,
            media: response.data.minutes.may ? response.data.minutes.may : 0,
          },
          {
            date: `${payload.yearInput}-06-01`,
            media: response.data.minutes.june ? response.data.minutes.june : 0,
          },
          {
            date: `${payload.yearInput}-07-01`,
            media: response.data.minutes.july ? response.data.minutes.july : 0,
          },
          {
            date: `${payload.yearInput}-08-01`,
            media: response.data.minutes.august ? response.data.minutes.august : 0,
          },
          {
            date: `${payload.yearInput}-09-01`,
            media: response.data.minutes.september ? response.data.minutes.september : 0,
          },
          {
            date: `${payload.yearInput}-10-01`,
            media: response.data.minutes.october ? response.data.minutes.october : 0 ,
          },
          {
            date: `${payload.yearInput}-11-01`,
            media: response.data.minutes.november ? response.data.minutes.november : 0,
          },
          {
            date: `${payload.yearInput}-12-01`,
            media: response.data.minutes.december ? response.data.minutes.december :0,
          },
        ]

        let minutesTotal = minutes.january? minutes.january : 0 + minutes.february ? minutes.february: 0 + minutes.march ? minutes.march : 0 + minutes.april ? minutes.april : 0 + minutes.may ? minutes.may : 0 + minutes.june?minutes.june:0 + minutes.july ? minutes.july :0 + minutes.august ? minutes.august :0 + minutes.september ? minutes.september:0 + minutes.october ? minutes.october : 0 + minutes.november ? minutes.november :0 + minutes.december ? minutes.december : 0


        let reportsTotal = reports.january ? reports.january : 0 + reports.february ? reports.february :0 + reports.march ? reports.march : 0 + reports.april ? reports.april : 0 + reports.may ? reports.may : 0 + reports.june ? reports.june : 0 + reports.july ? reports.july : 0 + reports.august ? reports.august : 0 + reports.september ? reports.september : 0 + reports.october ? reports.october : 0 + reports.november ? reports.november : 0 + reports.december ? reports.december : 0

        let num = minutesTotal;
        let hours = num / 60;
        let rhours = Math.floor(hours);
        let minutes_cal = (hours - rhours) * 60;
        let rminutes = Math.round(minutes_cal);
        minutesTotal = `${rhours}hrs ${rminutes}min`;

        let formattedData = minutesByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataMinutes = [
          {
            name: "test",
            data: formattedData,
          },
        ];
        let formattedDataReports = reportsByYear.map((e) => {
          return {
            x: e.date,
            y: e.media,
          };
        });
  
        let dataReports = [
          {
            name: "test",
            data: formattedDataReports,
          },
        ];

        commit("SET_MINUTES_BY_YEAR", minutesByYear)
        commit("SET_REPORTS_BY_YEAR", reportsByYear)
        commit("SET_MINUTES_TOTAL", minutesTotal)
        commit("SET_REPORTS_TOTAL", reportsTotal)
        commit("SET_VALUES_GRAPH_MINUTES", dataMinutes)
        commit("SET_VALUES_GRAPH_REPORTS", dataReports)
        commit("LOADING_STATUS", false);
      }
    });
  },
  
};
const mutations = {
  LOADING_STATUS(state,status){
    state.loader = status
  },
  GRAPHIC_REPORTS(state, graphicReports) {
    state.graphicReports = graphicReports;
  },
  GRAPHIC_HOURS(state, graphicHours) {
    state.graphicHours = graphicHours;
  },
  SET_MINUTES_BY_YEAR(state, minutes) {
    state.minutesByYear = minutes
  },
  SET_MINUTES_TOTAL(state, minutesTotal) {
    state.minutesTotal = minutesTotal
  },
  SET_VALUES_GRAPH_MINUTES(state, dataMinutes){
    state.dataMinutes = dataMinutes
  },
  SET_REPORTS_BY_YEAR(state, reportes) {
    state.reportsByYear = reportes
  },
  SET_REPORTS_TOTAL(state, reportsTotal) {
    state.reportsTotal = reportsTotal
  },
  SET_VALUES_GRAPH_REPORTS(state, dataReports){
    state.dataReports = dataReports
  },
  UPDATE_YEAR(state,year){
    state.yearSelected = year
  }
};
const getters = {
  reportsGraph: (state) => state.graphicReports,
  hoursGraph: (state) => state.graphicHours,
  minutesByYear: (state) => state.minutesByYear,
  minutesTotal: (state) => state.minutesTotal,
  dataMinutes: (state) => state.dataMinutes,
  reportsByYear: (state) => state.reportsByYear,
  reportsTotal: (state) => state.reportsTotal,
  dataReports: (state) => state.dataReports,
  typeShowGraph : (state) => state.typeShowGraph,
  yearSelected: (state) => state.yearSelected,
  loaderStadistics: (state) => state.loader
};

export default {
  state,
  actions,
  mutations,
  getters,
};
