import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import * as firebase from "firebase/app";
import VueApexCharts from 'vue-apexcharts'
import { getAnalytics } from "firebase/analytics";
import VueExcelXlsx from "vue-excel-xlsx";
import {getFunctions} from 'firebase/functions';
import { /* connectAuthEmulator ,*/ getAuth, onAuthStateChanged } from "firebase/auth";
import moment from 'moment'
import "toastify-js/src/toastify.css"

Vue.config.productionTip = false;
Vue.prototype.moment = moment;
moment.locale('es');
Vue.use(VueExcelXlsx);
new Vue({
  vuetify,
  router,
  store,
  VueApexCharts,
  created() {
    const firebaseConfig = {
      apiKey: "AIzaSyDgpwCLpWeI679h9WkVVNlVlTwrRQfk3Ow",
      authDomain: "hourcounter-1bc0f.firebaseapp.com",
      projectId: "hourcounter-1bc0f",
      storageBucket: "hourcounter-1bc0f.appspot.com",
      messagingSenderId: "176122761315",
      appId: "1:176122761315:web:27791aa29953994f3da856",
      measurementId: "G-MRTM8N6XYD"
    };
    const app = firebase.initializeApp(firebaseConfig);

    const auth = getAuth();
    // connectAuthEmulator(auth, "http://localhost:9099");

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.dispatch("initializeAuth", user);

      } else {
        console.warn("Without user in Firebase authentication");
      }
    });
    
    /* eslint-disable */ 
    const analytics = getAnalytics(app);
    const functions = getFunctions();
  },
  render: function (h) {
    return h(App);
  },
}).$mount("#app");