import Vue from "vue";
import Vuex from "vuex";
import ENUM from "@/store/enums";
import router from "@/router";
import Toastify from 'toastify-js'

import {
  getFunctions,
  // connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";

Vue.use(Vuex);
const state = {
  companyID: "",
  officeSelectedID: "",
  activeOffices: [],
  editingOfice: [],
  newAreas: [],
  areasById: [],
  status: ENUM.INIT,
  clientStatus: ENUM.INIT,
  loadingStatus: false,
  officeCreated: false,
  officeDeleted: false,
  officeAlert: false,
  hoursAndReports: {},
  officeNameSelected: "",
  readAreas: [],
  proyects: []
};
const actions = {
  async getAllActiveOffices({ commit }) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readAllOffices = httpsCallable(functions, "readAllActiveOffices");
    const readCompany = this.state.login.companyID;

    await readAllOffices({
      companyID: readCompany,
    }).then((result) => {
      let allOfficesDetails = result.data;
      // if(allOfficesDetails === "No office found!"){
      //   allOfficesDetails = []
      // }
      commit("LOADING_STATUS", false);
      commit("SET_ALL_OFFICES", allOfficesDetails);
    });
  },

  async createNewOffice({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const createNewOffice = httpsCallable(functions, "createNewOffice");
    await createNewOffice(payload)
      .then((res) => {
        // entro
        // console.log(res.data, "res createOfice")

        if (res.data !== "Office limit reached") {
          // connectFunctionsEmulator(functions, "localhost", 5001);
          const readAllOffices = httpsCallable(functions, "readAllActiveOffices");
          const readCompany = this.state.login.companyID;

          readAllOffices({
            companyID: readCompany,
          }).then((result) => {
            // YA ENCONTRO LAS OFINICINAS
            const activeOffices = result.data;
            commit("SET_OFFICE_CREATED", false);
            commit("LOADING_STATUS", false);
            commit("SET_ALL_OFFICES", activeOffices);

            commit("SET_OFFICE_ALERT", true);
          });
        }else{
          commit("LOADING_STATUS", false);
          Toastify({
            text: "Has alcanzado el limite de Oficinas, comunícate con el equipo de ventas.",
            duration: 5000,
            newWindow: true,
            close: true,
            gravity: "top", // `top` or `bottom`
            position: "center", // `left`, `center` or `right`
            style: {
              background: "#1d3051",
            },
          }).showToast();
        }
      })
      .catch((error) => {
        //fallo
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  },

  async deleteOffice({ commit }, officeID) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const deleteOffice = httpsCallable(functions, "deleteOfficeById");
    const companyId = this.state.login.companyID;
    const userUid = this.getters.getUserUID;

    await deleteOffice({
      companyID: companyId,
      userUID: userUid,
      officeID,
    })
      .then(() => {
        //YA LO BORRO DE LA BASE DATOS
        // connectFunctionsEmulator(functions, "localhost", 5001);
        const readAllOffices = httpsCallable(functions, "readAllActiveOffices");
        const readCompany = this.state.offices.companyID;

        readAllOffices({
          companyID: readCompany,
        }).then((result) => {
          // YA ENCONTRO LAS OFINICINAS
          const allOfficesDetails = result.data;
          commit("LOADING_STATUS", false);
          commit("SET_OFFICE_DELETED", true)
          commit("SET_ALL_OFFICES", allOfficesDetails);
        });
      })
      .catch((error) => {
        //fallo
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  },

  async updateAreasByOffice({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const updateAreas = httpsCallable(functions, "updateOfficeAreas");


    updateAreas(payload).then(() => {
      // connectFunctionsEmulator(functions, "localhost", 5001);
      const readAllOffices = httpsCallable(functions, "readAllActiveOffices");
      const readCompany = this.state.offices.companyID;
      readAllOffices({
        companyID: readCompany,
      }).then((result) => {
        const allOfficesDetails = result.data;
        commit("LOADING_STATUS", false);
        commit("SET_ALL_OFFICES", allOfficesDetails);
      });
    });
  },
  async replaceAreaByOffice({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const replaceAreas = httpsCallable(functions, "replaceAreaByOffice");

    await replaceAreas(payload).then(() => {
      // connectFunctionsEmulator(functions, "localhost", 5001);
      const readAllOffices = httpsCallable(functions, "readAllActiveOffices");
      const readCompany = this.state.offices.companyID;
      readAllOffices({
        companyID: readCompany,
      }).then((result) => {
        const allOfficesDetails = result.data;
        commit("LOADING_STATUS", false);
        commit("SET_ALL_OFFICES", allOfficesDetails);
      });
    });
  },
  async updateOfficeById({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const updateOffice = httpsCallable(functions, "updateOfficeById");


    updateOffice(payload).then(() => {
      // connectFunctionsEmulator(functions, "localhost", 5001);
      const readAllOffices = httpsCallable(functions, "readAllActiveOffices");
      const readCompany = this.state.offices.companyID;
      readAllOffices({
        companyID: readCompany,
      }).then((result) => {
        const allOfficesDetails = result.data;
        commit("LOADING_STATUS", false);
        commit("SET_ALL_OFFICES", allOfficesDetails);
      });
    });
  },
  async readUserById({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readUserById = httpsCallable(functions, "readUserById");
    await readUserById(payload).then((response) => {
      const companyID = response.data[0].userCompanyId;
      commit("LOADING_STATUS", false);
      commit("SET_COMPANY_ID", companyID);
      if (response.data[0].userType !== "ADMIN") {
        router.replace("/reportes");
      }
    });
  },
  async readClientDataSumToday({ commit }, payload) {
    // commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readClientDataSumToday = httpsCallable(functions, "readClientDataSumToday");
    await readClientDataSumToday(payload).then((result) => {
      let data = result.data
      let num = data.minutes;
      let hours = (num / 60);
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      data.minutes = rhours + "hrs" + " " + rminutes + "min"
      commit("SET_HOURS_REPORTS_BY_CLIENT", data);
    });
  },
  async readAreasByOffice({ commit }, payload) {
    // commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readAreasByOffice = httpsCallable(functions, "readOfficeAreas");
    await readAreasByOffice(payload).then((result) => {
      // connectFunctionsEmulator(functions, "localhost", 5001);
      let data = result.data
      // console.log(data, "result.data")
      commit("SET_AREAS_BY_OFFICES", data);
    });
  },
  async addAreasByOffice({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const addOfficeArea = httpsCallable(functions, "addOfficeArea");
    await addOfficeArea(payload).then((result) => {
      // connectFunctionsEmulator(functions, "localhost", 5001);
      let data = result.data
      // console.log(data, "result.data")
      commit("SET_AREAS_BY_OFFICES", data);

      const readAllOffices = httpsCallable(functions, "readAllActiveOffices");
      const readCompany = this.state.offices.companyID;
      readAllOffices({
        companyID: readCompany,
      }).then((result) => {
        let allOfficesDetails = result.data;
        commit("LOADING_STATUS", false);
        commit("SET_ALL_OFFICES", allOfficesDetails);
      });
    });
  },
  async readProyectsByClients({ commit }, payload) {
    // commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readProyectsByClients = httpsCallable(functions, "readClientProyects");
    await readProyectsByClients(payload).then((result) => {
      // connectFunctionsEmulator(functions, "localhost", 5001);
      let data = result.data
      // console.log(data, "result.data Proyects")
      commit("SET_REPORTS_BY_CLIENTS", data);
    });
  },
  async addProyectByClient({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const addProyectByClient = httpsCallable(functions, "updateClientProyects");
    await addProyectByClient(payload).then((result) => {
      // connectFunctionsEmulator(functions, "localhost", 5001);
      let data = result.data
      // console.log(data, "result.data")
      commit("SET_PROYECTS_BY_CLIENT", data);

      const readAllOffices = httpsCallable(functions, "readAllActiveOffices");
      const readCompany = this.state.offices.companyID;
      readAllOffices({
        companyID: readCompany,
      }).then((result) => {
        let allOfficesDetails = result.data;
        commit("LOADING_STATUS", false);
        commit("SET_ALL_OFFICES", allOfficesDetails);
      });
    });
  },
};
const getters = {
  allActiveOffices: (state) => state.activeOffices,
  // activeAreas: (state) => state.areasById[0].officeArea,
  officeCreated: (state) => state.officeCreated,
  // clientsByOffice: (state) => state.getAllClientsByOffice,
  loadingStatus: (state) => state.loadingStatus,
  officeSelectedID: (state) => state.officeSelectedID,
  hoursAndReportsByCliente: (state) => state.hoursAndReports,
  readAreas: (state) => state.readAreas,
  readReports: (state) => state.reports
};
const mutations = {
  SET_ALL_OFFICES(state, activeOffices) {
    state.activeOffices = activeOffices;
    state.status = ENUM.LOADED;
  },
  GET_ID_OFFICE_SELECTED(state, officeSelectedID) {
    state.officeSelectedID = officeSelectedID;
  },
  GET_AREA_BY_ID(state, officeID) {
    let areasAll = state.activeOffices.filter((a) => a.officeID === officeID);
    state.areasById = areasAll;
  },
  ALL_CLIENTS_OFFICE(state, getAllClientsByOffice) {
    state.getAllClientsByOffice = getAllClientsByOffice;
    state.clientStatus = ENUM.LOADED;
  },
  SET_OFFICE_CREATED(state, officeCreated) {
    state.officeCreated = officeCreated;
  },
  SET_OFFICE_DELETED(state, officeDel) {
    state.officeDeleted = officeDel
  },
  SET_OFFICE_ALERT(state, officeAlert) {
    state.officeAlert = officeAlert;
  },
  GET_OFFICE_NAME(state, officeNameSelected) {
    state.officeNameSelected = officeNameSelected;
  },
  SET_COMPANY_ID(state, companyID) {
    state.companyID = companyID;
  },
  LOADING_STATUS(state, newLoadingStatus) {
    state.loadingStatus = newLoadingStatus;
  },
  SET_HOURS_REPORTS_BY_CLIENT(state, hoursAndReports) {
    state.hoursAndReports = hoursAndReports
  },
  SET_AREAS_BY_OFFICES(state, readAreas) {
    state.readAreas = readAreas
  },
  SET_REPORTS_BY_CLIENTS(state, reports) {
    // console.log(reports, "mutation")
    state.reports = reports
  },
  SET_PROYECTS_BY_CLIENT(state, proyects) {
    state.reports = proyects
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
