import Vue from "vue";
import VueRouter from "vue-router";
import * as firebase from "firebase/app";
import {getAuth ,/* connectAuthEmulator */} from 'firebase/auth'

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  {
    path : '*',
    redirect:'/'
  },
  {
    path: "/",
    name: "loginView",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
  },
  
  {
    path: "/mi-cuenta",
    name: "myAccountView",
    component: () =>
      import(/* webpackChunkName: "mi-cuenta" */ "../views/MyAccountView.vue"),
    meta:{
      autentificado:true
    }
  },
  {
    path: "/mi-resumen",
    name: "myResume",
    component: () =>
      import(/* webpackChunkName: "mi-resumen" */ "../views/MyResume.vue"),
    meta:{
      autentificado:true
    }
  },
  {
    path: "/usuarios",
    name: "usersView",
    component: () =>
      import(/* webpackChunkName: "usuarios" */ "../views/UsersView.vue"),
    meta:{
      autentificado:true
    }
  },
  {
    path: "/reportes",
    name: "reportsView",
    component: () =>
      import(/* webpackChunkName: "reportes" */ "../views/ReportsView.vue"),
    meta:{
      autentificado:true
    }
  },
  {
    path: "/nuevo-cliente",
    name: "newClient",
    component: () =>
      import(/* webpackChunkName: "nuevo-cliente" */ "../views/NewClient.vue"),
    meta:{
      autentificado:true
    }
  },
  {
    path: "/estadisticas",
    name: "statistics",
    component: () =>
      import(/* webpackChunkName: "statistics" */ "../views/StatisticsView.vue"),
    meta:{
      autentificado:true
    }
  },
];


const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async(to, from, next)=>{
  firebase.initializeApp({
    apiKey: "AIzaSyDgpwCLpWeI679h9WkVVNlVlTwrRQfk3Ow",
    authDomain: "hourcounter-1bc0f.firebaseapp.com",
    projectId: "hourcounter-1bc0f",
    storageBucket: "hourcounter-1bc0f.appspot.com",
    messagingSenderId: "176122761315",
    appId: "1:176122761315:web:27791aa29953994f3da856",
    measurementId: "G-MRTM8N6XYD"
  });
  const auth = getAuth();
  let usuario = auth.currentUser;
  let autorizacion = to.matched.some(record => record.meta.autentificado)
  if(autorizacion && !usuario){
    next('/')
  }else if(!autorizacion && usuario){
    next('/mi-cuenta')
  }else{
    next()
  }
});

export default router;
