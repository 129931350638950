import Vue from "vue";
import Vuex from "vuex";
import Toastify from 'toastify-js'
// import ENUM from "@/store/enums";
import {
  getFunctions,
  // connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";

Vue.use(Vuex);

const state = {
  clients: [],
  clientSelected: [],
  clientCreated: false,
  clientDeleted: false,
  clientUpdated: false,
  getAllClientsByOffice: [],
  clientArray: "",
  loadingStatus: false,
};

const actions = {
  async createCliente({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const createNuevoCliente = httpsCallable(functions, "createNewClient");
    await createNuevoCliente(payload).then((res) => {
      if (res.data !== "Client limit reached") {
        // console.log("entro")
        const body = {
          companyID: payload.companyID,
          officeID: payload.officeIDs[0],
        };
        const readAllClients = httpsCallable(functions, "readAllActiveClients");
        readAllClients(body).then((res) => {
          const readAllClients = res.data;
          commit("LOADING_STATUS", false);
          commit("CLIENT_CREATED_ALERT", true);
          commit("SET_ALL_CLIENTS", readAllClients);
        });
      }else{
        commit("LOADING_STATUS", false);
        commit("CLIENT_CREATED_ALERT", false);
        Toastify({
          text: "Has alcanzado el limite de Clientes, comunícate con el equipo de ventas.",
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "center", // `left`, `center` or `right`
          style: {
            background: "#1d3051",
          },
        }).showToast();
      }
    });
  },
  async readAllActiveClients({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readAllClients = httpsCallable(functions, "readAllActiveClients");
    await readAllClients(payload).then((res) => {
      const readAllClients = res.data;
      commit("LOADING_STATUS", false);
      commit("SET_NEW_CLIENT", readAllClients); // change mutation
    });
  },
  // deleteGroupClients
  async deleteGroupClients({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    // connectFunctionsEmulator(functions, "localhost", 5001);
    const deleteGroupClients = httpsCallable(functions, "deleteGroupClients");
    await deleteGroupClients(payload).then(() => {
      const readAllClients = httpsCallable(functions, "readAllActiveClients");
      const readCompany = this.state.offices.companyID;
      const readOfficeId = this.state.offices.officeSelectedID;
      readAllClients({
        companyID: readCompany,
        officeID: readOfficeId,
      }).then((res) => {
        const allClientsByOffice = res.data;
        if (allClientsByOffice === "No active clients avaliable.") {
          commit("SET_ALL_CLIENTS", "");
          return;
        }
        commit("LOADING_STATUS", false);
        commit("CLIENT_DELETED_ALERT", true);
        commit("SET_ALL_CLIENTS", allClientsByOffice);
      });
    });
  },
  async getAllActiveClients({ commit }) {
    commit("LOADING_STATUS", false);
    const functions = getFunctions();

    // connectFunctionsEmulator(functions, "localhost", 5001);
    const readAllClients = await httpsCallable(
      functions,
      "readAllActiveClients"
    );

    const readCompany = this.state.offices.companyID;
    const readOfficeId = this.state.offices.officeSelectedID;

    await readAllClients({
      companyID: readCompany,
      officeID: readOfficeId,
    })
      .then((result) => {
        let allClientsByOffice = result.data;
        if (allClientsByOffice === "No active clients avaliable.") {
          commit("SET_ALL_CLIENTS", "");
          return;
        }
        allClientsByOffice = result.data.sort(function (a, b) {
          if (a.clientName.toLowerCase() > b.clientName.toLowerCase()) {
            return 1;
          }
          if (a.clientName.toLowerCase() < b.clientName.toLowerCase()) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        commit("LOADING_STATUS", false);
        commit("SET_ALL_CLIENTS", allClientsByOffice);
      })
      .catch((error) => {
        //fallo
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  },

  async updateClientById({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    const updateClientById = httpsCallable(functions, "updateClientById");
    await updateClientById(payload).then(() => {
      const readAllClients = httpsCallable(functions, "readAllActiveClients");
      const readCompany = this.state.offices.companyID;
      const readOfficeId = this.state.offices.officeSelectedID;
      readAllClients({
        companyID: readCompany,
        officeID: readOfficeId,
      }).then((res) => {
        const allClientsByOffice = res.data;
        if (allClientsByOffice === "No active clients avaliable.") {
          commit("SET_ALL_CLIENTS", "");
          return;
        }
        commit("LOADING_STATUS", false);
        commit("CLIENT_UPDATED_ALERT", true);
        commit("SET_NEW_CLIENT", allClientsByOffice);
        commit("SET_ALL_CLIENTS", allClientsByOffice);
      });
    });
  },
};

const getters = {
  allClientes: (state) => state.clients,
  clientsByOffice: (state) => state.getAllClientsByOffice,
  clientNameCurrent: (state) => state.clientSelected[0].clientName,
  loadingStatusClients(state) {
    return state.loadingStatusClients;
  },
  loadingClientsFull(state) {
    return state.loadingClientsFull;
  },
  filteredClient: (state) => state.clientSelected[0],
};
const mutations = {
  SET_NEW_CLIENT(state, nuevoCliente) {
    state.clients = nuevoCliente;
  },
  GET_ID_CLIENT_SELECTED(state, clientID) {
    let clientSel = state.getAllClientsByOffice.filter(
      (a) => a.clientID === clientID
    );
    state.clientSelected = clientSel;
  },
  SET_ALL_CLIENTS(state, clients) {
    state.getAllClientsByOffice = clients;
  },
  DELETE_CLIENT(state, boolean) {
    state.deleteCliente = boolean;
  },
  LOADING_STATUS(state, newLoadingStatus) {
    state.loadingStatus = newLoadingStatus;
  },
  CLIENT_CREATED_ALERT(state, clientCreated) {
    state.clientCreated = clientCreated;
  },
  CLIENT_UPDATED_ALERT(state, clientUpdatedA) {
    state.clientUpdated = clientUpdatedA;
  },
  CLIENT_DELETED_ALERT(state, clientDeletedA) {
    state.clientDeleted = clientDeletedA;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
