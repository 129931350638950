import Vue from "vue";
import Vuex from "vuex";
import {
  getFunctions,
  // connectFunctionsEmulator,
  httpsCallable,
} from "firebase/functions";
import Toastify from 'toastify-js'
// import moment from "moment";

Vue.use(Vuex);
const state = {
  allTaskForClient: [],
  allTaskForClient1: [],
  createdTask: false,
  editedTask: false,
  deletedTask: false,
  selectedTask: [],
  selectedTaskReport: [],
  loadingStatus: false,
  allTaksToday: [],
  usersToday: [],
  taskToday: [],
  tiempo: "0",
  reportes: 0,
  showCaptionTimer: true,
  mounthFiltered: ""
};

const actions = {
  async readTaksbyClientID({ commit }, payload) {
    commit("LOADING_STATUS", true);
    
    const functions = getFunctions();
    const readTask = httpsCallable(functions, "readAllActiveTasks");
    // connectFunctionsEmulator(functions, "localhost", 5001);
    await readTask(payload).then((result) => {
      let allTasksDetails = result.data;
      // console.log(allTasksDetails, "allTasksDetails")
      if (this.state.login.userType === "REGULAR" && allTasksDetails !== "No active tasks found!" ) {
        let nuevoArreglo = allTasksDetails.filter((item) => {
          return item.taskCreatedBy === this.state.login.user.displayName;
        });
        allTasksDetails = nuevoArreglo
      }
      
      let ArrayFormat = allTasksDetails
      if (allTasksDetails === "No active tasks found!") {
     
        allTasksDetails = [];
        ArrayFormat = [];
      } else {
        ArrayFormat.sort((a, b) => {
          if (new Date(a.taskCreatedAt).getTime() > new Date(b.taskCreatedAt).getTime()) {
            return -1;
          }
          if (new Date(a.taskCreatedAt).getTime() < new Date(b.taskCreatedAt).getTime()) {
            return 1;
          }
          return 0;
        })
      }
      // console.log(ArrayFormat, "ArrayFormat")
      commit("LOADING_STATUS", false);
      commit("SET_ALL_TASKS", ArrayFormat);
    });
  },
  async createReport({ commit }, payload) {
    commit("LOADING_STATUS", true);
    // console.log(payload, "payload")
    const functions = getFunctions();
    const createReport = httpsCallable(functions, "createNewTask");
    await createReport(payload).then((res) => {
      const readTask = httpsCallable(functions, "readAllActiveTasks");
      const limit = "Tasks limit reached, Task" + " " + payload.taskName + " " + "hasn't been created"
      // "Tasks limit reached, Task nuevoProyecto hasn't been created"
      if (res.data === limit) {
        commit("LOADING_STATUS", false);
        commit("CREATED_TASK_ALERT", false);
        Toastify({
          text: "Has alcanzado el limite de Reportes, comunícate con el equipo de ventas.",
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: "top", // `top` or `bottom`
          position: "center", // `left`, `center` or `right`
          style: {
            background: "#1d3051",
          },
        }).showToast();
      } else {
        readTask(payload).then((result) => {
          const body = {
            clientID: payload.clientID,
            companyID: this.state.login.companyID,
            officeID: payload.officeID,
          };
          this.dispatch("readClientDataSumToday", body);
          let allTasksDetails = result.data;
          if (allTasksDetails === "No active tasks found!") {
            allTasksDetails = [];
          }
          commit("LOADING_STATUS", false);
          commit("CREATED_TASK_ALERT", true);
          commit("SET_ALL_TASKS", allTasksDetails);
        });
      }
    });
  },
  async updateTaskById({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    const updateReport = httpsCallable(functions, "updateTaskById");
    // connectFunctionsEmulator(functions, "localhost", 5001);
    await updateReport(payload).then(() => {
      const readTask = httpsCallable(functions, "readAllActiveTasks");
      // connectFunctionsEmulator(functions, "localhost", 5001);
      readTask(payload).then((result) => {
        let allTasksDetails = result.data;
        if (allTasksDetails === "No active tasks found!") {
          allTasksDetails = [];
        }
        commit("LOADING_STATUS", false);
        commit("UPDATE_TASK_ALERT", true);
        commit("SET_ALL_TASKS", allTasksDetails);
      });
    });
  },
  async deleteReport({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    const deleteReport = httpsCallable(functions, "deleteTasks");
    // connectFunctionsEmulator(functions, "localhost", 5001);
    await deleteReport(payload).then(() => {
      const readTask = httpsCallable(functions, "readAllActiveTasks");
      // connectFunctionsEmulator(functions, "localhost", 5001);

      readTask(payload).then((result) => {
        const body = {
          clientID: payload.clientID,
          companyID: this.state.login.companyID,
          officeID: payload.officeID,
        };
        this.dispatch("readClientDataSumToday", body);
        let allTasksDetails = result.data;
        if (allTasksDetails === "No active tasks found!") {
          allTasksDetails = [];
        }
        // console.log(allTasksDetails)
        commit("LOADING_STATUS", false);
        commit("DELETED_TASK_ALERT", true);
        commit("SET_ALL_TASKS", allTasksDetails);
      });
    });
  },
  async readAllActiveTasksToday({ commit }, payload) {
    commit("LOADING_STATUS", true);
    const functions = getFunctions();
    const readAllActiveTasksToday = httpsCallable(
      functions,
      "readAllActiveTasksToday"
    );
    // connectFunctionsEmulator(functions, "localhost", 5001);
    await readAllActiveTasksToday(payload).then((result) => {
      let readAllActiveTask = result.data;
      let tiempo = 0;
      readAllActiveTask.tasks.map((item) => {
        tiempo = tiempo + item.taskWorkingMinutes;
      });
      let hours_tiempo = tiempo / 60;
      let rhours_tiempo = Math.floor(hours_tiempo);
      let minutes_tiempo = (hours_tiempo - rhours_tiempo) * 60;
      let rminutes_tiempo = Math.round(minutes_tiempo);
      tiempo = `${rhours_tiempo}hrs ${rminutes_tiempo}min`;
      readAllActiveTask.tasks.map((item) => {
        let num = item.taskWorkingMinutes;
        let hours = num / 60;
        let rhours = Math.floor(hours);
        let minutes = (hours - rhours) * 60;
        let rminutes = Math.round(minutes);
        item.taskWorkingMinutes = `${rhours}hrs ${rminutes}min`;
      });
      // console.log(readAllActiveTask.tasks, "active task")
      commit("LOADING_STATUS", false);
      commit("TIEMPO", tiempo);
      commit("REPORTES", readAllActiveTask.tasks.length);
      commit("ALL_TASK_TODAY", readAllActiveTask.tasks);
      commit("TASK_TODAY", readAllActiveTask.tasks);
      commit("USERS_TODAY", readAllActiveTask.users);
    });
  },
};

const mutations = {
  SET_ALL_TASKS(state, allTaskForClient) {
    state.allTaskForClient = allTaskForClient;
    state.allTaskForClient1 = allTaskForClient;
  },
  CREATED_TASK_ALERT(state, newTask) {
    state.createdTask = newTask;
  },
  UPDATE_TASK_ALERT(state, editTask) {
    state.editedTask = editTask;
  },
  DELETED_TASK_ALERT(state, deleteTask) {
    state.deletedTask = deleteTask;
  },
  GET_INFO_TASK(state, taskID) {
    let task = state.taskToday.filter((a) => a.taskID === taskID);
    state.selectedTask = task;
  },
  GET_INFO_TASK_REPORT(state, taskID) {
    let task = state.allTaskForClient.filter((a) => a.taskID === taskID);
    state.selectedTaskReport = task;
  },
  ALL_TASK_TODAY(state, allTaksToday) {
    state.allTaksToday = allTaksToday;
  },
  TASK_TODAY(state, taskToday) {
    state.taskToday = taskToday;
  },
  USERS_TODAY(state, UsersToday) {
    UsersToday.unshift("Todos");
    state.usersToday = UsersToday;
  },
  LOADING_STATUS(state, newLoadingStatus) {
    console.log("asdasda",newLoadingStatus)
    state.loadingStatus = newLoadingStatus;
  },
  TIEMPO(state, tiempo) {
    state.tiempo = tiempo;
  },
  REPORTES(state, reportes) {
    state.reportes = reportes;
  },
};

const getters = {
  allActiveTask: (state) => state.allTaskForClient,
  allActiveTask1: (state) => state.allTaskForClient1,
  usersToday: (state) => state.usersToday,
  allTaksToday: (state) => state.allTaksToday,
  getTask: (state) => state.selectedTask,
  taskToday: (state) => state.taskToday,
  taskTodayReport: (state) => state.selectedTaskReport,
  tiempo: (state) => state.tiempo,
  reportes: (state) => state.reportes,
};

export default {
  state,
  actions,
  mutations,
  getters,
};
